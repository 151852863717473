// @flow
import React from 'react'

import TopBar from 'src/components/Payment/TopBar'
import PageFooterContactInfo from 'src/components/PageFooterContactInfo'
import PageHeader from 'src/components/Payment/PageHeader'
import PageFooter from 'src/components/Payment/PageFooter'
import AuthorizeNetForm from 'src/components/Payment/AuthorizeNetForm'
import MockPaymentForm from 'src/components/Payment/MockPaymentForm'
import NMIForm from 'src/components/Payment/NMIForm'
import StripeForm from 'src/components/Payment/StripeForm'
import PayPalForm from 'src/components/Payment/PayPalForm'

import { Store } from 'src/Store'

export default function Payment () {
    const { state } = React.useContext(Store)

    const paymentForm = React.useMemo(() => {
        if (typeof state.campaign['payment-gateways']['mock'] !== 'undefined') {
            return (
                <MockPaymentForm />
            )
        }

        if (typeof state.campaign['payment-gateways']['authorize.net'] !== 'undefined') {
            return (
                <AuthorizeNetForm />
            )
        }

        if (typeof state.campaign['payment-gateways']['nmi'] !== 'undefined') {
            return (
                <NMIForm />
            )
        }

        if (typeof state.campaign['payment-gateways']['stripe'] !== 'undefined') {
            return (
                <StripeForm />
            )
        }

        if (typeof state.campaign['payment-gateways']['paypal'] !== 'undefined') {
            return (
                <PayPalForm />
            )
        }
    }, [state.campaign])

    return (
        <>
            <TopBar />
            <PageHeader />

            {paymentForm}

            <PageFooter />
            <PageFooterContactInfo />
        </>
    )
}
