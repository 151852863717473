// @flow
import React from 'react'
import queryString from 'query-string'
import { Store } from 'src/Store'
import Url from 'url-parse'

export default function (newParam) {
    const {
        state: {
            campaign,
            lead,
            campaignUrl
        }
    } = React.useContext(Store)

    const signOutUrl = new Url(campaignUrl.toString())
    signOutUrl.set('pathname', campaignUrl.pathname + '/success')
    signOutUrl.set('query', window.location.search)

    console.log(signOutUrl.toString())

    const args = {
        itemCode: campaign['payment-gateways'].cashnet.itemCode,
        amount: Number(lead.fields.donationAmount).toFixed(2),
        fname: lead.fields.firstName,
        lname: lead.fields.lastName,
        city: lead.fields.city,
        state: lead.fields.province,
        zip: lead.fields.postalCode,
        addr: lead.fields.addressLine1,
        email: lead.fields.email,
        signouturl: signOutUrl.toString(),
        NAME_G: `${lead.fields.firstName} ${lead.fields.lastName}`.trim(),
        ADDR_G: lead.fields.addressLine1,
        CITY_G: lead.fields.city,
        STATE_G: lead.fields.province,
        ZIP_G: lead.fields.postalCode,
        EMAIL_G: lead.fields.email,
        PHONE_G: lead.fields.homePhone

    }

    const url = campaign['payment-gateways'].cashnet.storeUrl + '?' + queryString.stringify(args)

    window.location.replace(url)

    return <h1>Redirecting to CASHNet payment form...</h1>
}
