// @flow
import React from 'react'

import { sendAutoresponder } from 'src/Actions'
import { Store } from 'src/Store'

import TopBar from 'src/components/Payment/TopBar'
import PageFooterContactInfo from 'src/components/PageFooterContactInfo'
import PageHeader from 'src/components/Success/PageHeader'

export default function Success () {
    const { state: { campaign }, dispatch } = React.useContext(Store)

    React.useEffect(() => {
        if (campaign.hasOwnProperty('autoresponder')) {
            sendAutoresponder(dispatch)
        }
    }, [campaign, dispatch])

    return (
        <>
            <TopBar />
            <PageHeader />

            <PageFooterContactInfo />
        </>
    )
}
