// @flow
import queryString from 'query-string'
import merge from 'deepmerge'
import URL from 'url-parse'

import { Client, FetchClient, MockClient } from '@shift-marketing/shift-one-api-client'

import mockDataOverrides from 'src/mock-client-overrides.js'

const query = queryString.parse(window.location.search)

function makeClient (baseUri: ?string): Client {
    if (typeof query['demo'] !== 'undefined') {
        let options = mockDataOverrides

        if (query['demo']) {
            try {
                const demo = JSON.parse(query['demo'])
                options = merge(options, demo)
            } catch (error) {
                throw new Error('Unable to parse JSON')
            }
        }

        return new MockClient('higher-education', options)
    }

    return new FetchClient(baseUri)
}

const APIClient = makeClient(process.env.REACT_APP_SHIFT_API_BASE_URL)

let entrypoint = window.sessionStorage.getItem('entrypoint')
if (!entrypoint || (new URL(entrypoint)).pathname.split('/')[1] !== window.location.pathname.split('/')[1]) {
    entrypoint = query._impersonate
        ? `${window.location.protocol}//${query._impersonate}`
        : window.location.href

    window.sessionStorage.setItem('entrypoint', entrypoint)
}

const campaignUrl = new URL(entrypoint)

export const fetchCampaignAndLead = (dispatch: any => void) => {
    (async function () {
        try {
            const campaign = await APIClient.getCampaign(campaignUrl)

            campaign.expiryDate = new Date(campaign.clientExpiryDate)
            campaign.countdownExpiryDate = new Date(campaign.countdownExpiryDate)

            dispatch({
                type: 'SET_CAMPAIGN',
                payload: { campaign, campaignUrl }
            })

            dispatch({
                type: 'SET_LOCALE',
                payload: campaign.locales.default
            })

            const lead = await APIClient.getLead(campaignUrl)

            dispatch({
                type: 'SET_LEAD',
                payload: lead
            })
        } catch (error) {
            console.error(error)
            alert('Something went wrong.')
        }
    })()
}

export const updateLead = async (dispatch: any => void, fields: any, isComplete: boolean) => {
    const lead = await APIClient.patchLead(campaignUrl, null, fields, isComplete)

    dispatch({
        type: 'SET_LEAD',
        payload: lead
    })
}

export const preProcessPayment = async (amount: number, payload: Object) => {
    try {
        return await APIClient.preProcessPayment(campaignUrl, amount, payload)
    } catch (error) {
        console.error(error)
        alert('Something went wrong.')
        throw error
    }
}

export const processPayment = async (amount: number, payload: Object) => {
    try {
        return await APIClient.processPayment(campaignUrl, amount, payload)
    } catch (error) {
        console.error(error)
        alert('Something went wrong.')
        throw error
    }
}

export const processSubscription = (amount: number, payload: Object) => {
    try {
        return APIClient.processSubscription(campaignUrl, amount, 'monthly', payload)
    } catch (error) {
        console.error(error)
        alert('Something went wrong.')
    }
}

export function sendAutoresponder (dispatch: any => void, voucherName: string, voucher: File) {
    dispatch({
        type: 'SEND_AUTORESPONDER',
        payload: null
    })

    APIClient.sendAutoresponder(campaignUrl, { [voucherName]: voucher })
}
