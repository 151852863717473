// @flow
import React from 'react'

type Props = {
    condition: Boolean,
    children?: React.ReactChildren,
    then?: React.ReactChildren,
    else?: React.ReactChildren,
}

const If = (props: Props) => {
    if (props.then) {
        return props.condition
            ? props.then
            : props.else || null
    }

    return props.condition ? props.children : null
}

export default If
