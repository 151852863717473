// @flow
import * as React from 'react'
import SuccessModal from 'src/components/Payment/SuccessModal'
import styles from './styles.module.scss'
import themes from './themes/__supportedThemes'
import withTheme from 'src/hoc/withTheme'
import { Store } from 'src/Store'
import { processPayment } from '../../../Actions'

const PAYPAL_JS_SCRIPT_ID = 'paypal-js-script'

export default withTheme(themes)(function PaymentForm (props: Props) {
    const { state: { campaign, lead } } = React.useContext(Store)

    const [clientId, setClientId] = React.useState(null)
    const [merchantId, setMerchantId] = React.useState(null)
    const [loadingStatus, setLoadingStatus] = React.useState(false)
    const [status, setStatus] = React.useState(false)

    const smartButtonProps = {
        createOrder: (data, actions) => {
            return actions.order.create({
                purchase_units: [{
                    amount: {
                        value: lead.fields.donationAmount
                    }
                }],
                payer: {
                    name: {
                        given_name: lead.fields.firstName,
                        surname: lead.fields.lastName
                    },
                    email_address: lead.fields.email ? lead.fields.email : null
                }
            })
        },
        onApprove: (data, actions) => {
            return actions.order.capture().then((details) => {
                try {
                    processPayment(lead.fields.donationAmount, {
                        id: details.id
                    }).then(() => {
                        setStatus(true)
                    })
                } catch (err) {
                    alert('Oops something went wrong.')
                    console.error(err)
                }
            })
        },
        onCancel: () => {},
        onError: () => {},
        style: {
            layout: 'vertical',
            color: 'gold',
            shape: 'rect',
            height: 50, // between 25-50
            label: 'paypal'
        }
    }

    React.useEffect(() => {
        const paymentGateway = Object.keys(campaign['payment-gateways'])[0]

        if (paymentGateway !== 'paypal') return

        setClientId(campaign['payment-gateways'][paymentGateway].clientId)
        setMerchantId(campaign['payment-gateways'][paymentGateway].merchantId)
    }, [campaign])

    React.useEffect(() => {
        if (clientId) {
            const script = document.createElement('script')
            script.id = PAYPAL_JS_SCRIPT_ID

            if (merchantId) {
                script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&merchant-id=${merchantId}`
            } else {
                script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`
            }

            document.body.appendChild(script)

            script.onload = function () {
                setLoadingStatus(true)
            }

            return () => { document.body.removeChild(script) }
        }
    }, [clientId, merchantId])

    React.useEffect(() => {
        if (loadingStatus === true) {
            window.paypal
                .Buttons(smartButtonProps)
                .render('#SmartPaymentButtons')
        }
    }, [loadingStatus, smartButtonProps])

    return (
        <>
            <div className={styles.wrapper} style={{ '--border-color-wrapper': props.theme.borderColor }}>
                <div className={styles.inner}>
                    <div id="SmartPaymentButtons"/>
                </div>
            </div>
            {status && <SuccessModal/>}
        </>
    )
})
