// @flow
import React, { Suspense } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import { Store } from './Store'
import { fetchCampaignAndLead } from './Actions'
import i18n from 'src/i18n'

import Expired from 'pages/Expired'
import LandingPage from 'src/pages/LandingPage'
import Payment from 'src/pages/Payment'
import Success from 'src/pages/Success'

const IS_CAMPAIGN_URL = /^\/.+$/.test(window.location.pathname)

const history = createBrowserHistory()

export default function App () {
    const { state, dispatch } = React.useContext(Store)

    React.useEffect(() => {
        if (IS_CAMPAIGN_URL && state.campaign === null) {
            fetchCampaignAndLead(dispatch)
        }

        if (state.campaign) {
            document.title = state.campaign.client.name
        }

        window.top.postMessage(document.body.scrollHeight, '*')
    })

    React.useEffect(() => {
        if (IS_CAMPAIGN_URL && state.campaign === null) return
        setTimeout(() => {
            document.body.classList.remove('loading')
        }, 500)
    }, [state.campaign])

    React.useEffect(() => {
        if (state.locale === null) return

        i18n.changeLanguage(state.locale.toString()).then()
        document.documentElement.setAttribute('lang', state.locale.toString())
    }, [state.locale])

    if (IS_CAMPAIGN_URL && (state.campaign === null || state.lead === null)) {
        return <h1>Loading</h1>
    }

    if (state.campaign && state.campaign.expired) {
        return <Expired />
    }

    return (
        <Suspense fallback={<div>loading...</div>}>
            <Router history={history}>
                <Switch>
                    <Route exact path='/' component={Welcome} />
                    <Route exact path='/:url' component={LandingPage} />
                    <Route exact path='/:url/payment' component={Payment} />
                    <Route exact path='/:url/success' component={Success} />
                </Switch>
            </Router>
        </Suspense>
    )
}

function Welcome () {
    if (['mylobogift.org', 'myunmgift.org'].includes(window.location.hostname)) {
        return <div style={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
            minHeight: '100vh',
            justifyContent: 'center',
            maxWidth: '640px',
            padding: '20px',
            margin: '0 auto'
        }}>
            <p>This site is for the Lobo community at the University of New Mexico.</p>
            <p>The personalized landing site has distinct information for each person, so please use the link provided
                to see your page.</p>
            <p>Thank you and go Lobos!</p>
            <p>
                UNM Foundation, Inc.<br />
                700 Lomas Blvd NE<br />
                Two Woodward Center<br />
                Albuquerque, NM 87102<br />
                505.313.7686<br />
                giving@unmfund.org
            </p>
        </div>
    }

    return <h1>Welcome stranger.</h1>
}
