// @flow
import React from 'react'

import { Store } from 'src/Store'
import If from 'src/components/If'
import PageContainer from 'src/components/PageContainer'
import SocialIcons from 'src/components/SocialIcons'

import { withTranslation } from 'react-i18next'
import transDomain from './translations/index.translations'

import withTheme from 'src/hoc/withTheme'
import themes, { Theme } from './themes/__supportedThemes'
import Content from 'src/components/Content'

import styles from './styles.module.scss'

export default withTranslation(transDomain)(withTheme(themes)(function PageFooter (props: { theme: Theme }) {
    const { state: { campaign } } = React.useContext(Store)
    const address = campaign.client.address
    const socialLinks = campaign.socialLinks

    return (
        <footer className={styles.footer} style={{
            '--bg-color': props.theme.bgColor,
            'backgroundColor': props.theme.bgColor
        }}>
            <PageContainer>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flex: '0 0 auto', flexWrap: 'wrap', margin: '1em', width: '100%' }}>
                        {[
                            ((address.line1 || '') + ' ' + (address.line2 || '')).trim(),
                            address.city,
                            address.province,
                            address.postalCode
                        ].join(' ')}
                        <div className={styles.divider} />
                        <If condition={campaign.contactInfo.phoneNumber}>
                            phone: <a href={`tel:${campaign.contactInfo.phoneNumber}`}>{campaign.contactInfo.phoneNumber}</a>
                            <div className={styles.divider} />
                        </If>

                        <If condition={campaign.contactInfo.email}>
                            email: <a href={`mailto:${campaign.contactInfo.email}`}>{campaign.contactInfo.email}</a>
                        </If>
                    </div>

                    <Content wrapper={'div'} className={styles.disclaimer} contentKey={'pageFooter.disclaimer'} />

                    {socialLinks && socialLinks.length > 0 && <SocialIcons />}
                </div>
            </PageContainer>
        </footer>
    )
}))
