// @flow

const TEST = /^https:\/\/vimeo.com\/(\d+)$/

export default function extractVimeoId (url: ?string): ?number {
    if (!url) {
        return 0
    }

    const match = url.match(TEST)

    if (!match) {
        throw new Error('Cannot get embed code for non Vimeo video.')
    }

    return match[1]
}
