// @flow
import React from 'react'
import cn from 'classnames'
import withTheme from 'src/hoc/withTheme'

import styles from './styles.module.scss'
import themes, { type Theme } from './themes/__supportedThemes'

function Typography (props: { children: React.Children, theme: Theme, isCentered: boolean }) {
    return (
        <div
            className={cn(styles.typography, { [styles.isCentered]: props.isCentered })}
            style={{
                '--colorStrong': props.theme.strongColor,
                '--font-family-heading': props.theme.fontHeading,
                '--color-h1': props.theme.colorH1
            }}
        >
            {props.children}
        </div>
    )
}

Typography.defaultProps = {
    isCentered: false
}

export default withTheme(themes)(Typography)
