// @flow
import React from 'react'

import styles from './styles.module.scss'

import withTheme from 'src/hoc/withTheme'
import themes, { Theme } from './themes/__supportedThemes'

type Props = {
    theme: Theme
}

export default withTheme(themes)(function (props: Props) {
    return (
        <header className={styles.top} style={props.theme.style}>
            <img src={props.theme.logo} alt='Logo' className={styles.logo} />
        </header>
    )
})
