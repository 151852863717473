// @flow
import React from 'react'
import Choice from './Choice'
import { RowColumn, RowColumns } from '../index'
import { makeId } from 'src/components/Form/Helpers'

type Props = {
    choices: string[],
    defaultValue: string | string[],
    disabled?: boolean,
    required?: boolean,
    multiple: boolean,
    onChange: Function,
    labelFormat: string => string,
    vertical: boolean,
    columns?: number
};

export default function Choices (props: Props) {
    const name = makeId('choices-')
    const handleChange = (value: string) => {
        if (!props.multiple) {
            props.onChange(value)
            return
        }

        let values: string[] = props.value
        let index = props.value.indexOf(value)
        if (index === -1) {
            values.push(value)
        } else {
            delete values[index]
        }

        props.onChange(values)
    }

    let choices = props.choices.map(choice => (
        <Choice
            type={props.multiple ? 'checkbox' : 'radio'}
            key={choice}
            name={name}
            label={props.formatLabel(choice)}
            value={choice}
            required={props.required}
            disabled={props.disabled}
            defaultChecked={props.multiple ? props.defaultValue.indexOf(choice) !== -1 : props.defaultValue === choice}
            onChange={handleChange}
        />
    ))

    return (
        <RowColumns>
            {choices.map((choice, index) => (
                <RowColumn key={index} size={props.columns ? 1 / props.columns : null}>{choice}</RowColumn>
            ))}
        </RowColumns>
    )
}

Choices.defaultProps = {
    disabled: false,
    required: true,
    value: [],
    labelFormat: '%name%',
    vertical: false
}
