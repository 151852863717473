// @flow
import React from 'react'
import { Redirect } from 'react-router'
import CASHNetForm from 'src/components/Payment/CASHNetForm'
import BlackbaudCheckoutForm from 'src/components/Payment/BlackbaudCheckoutForm'
import { Store } from 'src/Store'

export default function ShowPaymentForm (props) {
    const path = window.location.pathname.substr(1).split('/')[0]

    const { state } = React.useContext(Store)
    const paymentGateway = Object.keys(state.campaign['payment-gateways'])[0]

    switch (paymentGateway) {
        case 'mock':
        case 'authorize.net':
        case 'nmi':
            return <Redirect to={`${path}/payment${window.location.search}`} />
        case 'cashnet':
            return <CASHNetForm />
        case 'blackbaud':
            return <BlackbaudCheckoutForm />
        case 'stripe':
            return <Redirect to={`${path}/payment${window.location.search}`} />
        case 'paypal':
            return <Redirect to={`${path}/payment${window.location.search}`} />
        case 'redirect':
            window.location.href = state.campaign['payment-gateways']['redirect']['url']
            return
        default:
            throw new Error('Unrecognized payment gateway')
    }
}
