// @flow
import React from 'react'

import { Store } from 'src/Store'

import styles from './TopBar.module.scss'

import withTheme from '../../hoc/withTheme'
import themes, { Theme } from './themes/__supportedThemes'

type Props = {
    theme: Theme
}

export default withTheme(themes)(function (props: Props) {
    const { state } = React.useContext(Store)

    return (
        <header
            className={styles.top}
            style={{
                '--color-background': props.theme.style.background,
                '--color-text': props.theme.style.color
            }}
        >
            <strong>{state.campaign.contactInfo.name}</strong>
            <div className={styles.divider} />
            {state.campaign.contactInfo.position}
            <div className={styles.divider} />
            <a href={'tel:' + state.campaign.contactInfo.phoneNumber}>{state.campaign.contactInfo.phoneNumber}</a>
            <div className={styles.divider} />
            <a href={'mailto:' + state.campaign.contactInfo.email}>{state.campaign.contactInfo.email}</a>
        </header>
    )
})
