// @flow
import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

export function Columns ({ children }) {
    return <div className={styles.columns}>{children}</div>
}

type ColumnProps = {
    size?: 'half' | 'left' | 'right',
    children: React.children,
    noSpacing: boolean,
    style: Object
}

export function Column ({ children, size, noSpacing, style }: ColumnProps) {
    return <div className={cn(styles.column, styles[size], { [styles.noSpacing]: noSpacing })} style={style}>{children}</div>
}

Column.defaultProps = {
    noSpacing: false
}
