// @flow
import React from 'react'
import { withRouter } from 'react-router'

import { Store } from 'src/Store'
import { updateLead } from 'src/Actions'

import If from 'src/components/If'
import TopBar from 'src/components/TopBar'
import Masthead from 'src/components/Masthead'
import Stats from 'src/components/Stats'
import Testimonials from 'src/components/Testimonials'
import PageContainer from 'src/components/PageContainer'
import PageFooter from 'src/components/PageFooter'
import PageFooterContactInfo from 'src/components/PageFooterContactInfo'
import LeadGenForm from 'src/components/LeadGenForm'
import Payment from 'src/components/Payment'

import themes, { type Theme } from './themes/__supportedThemes'
import withTheme from 'src/hoc/withTheme'

import styles from './styles.module.scss'

type Props = {
    location: Location,
    theme: Theme
}

export default withTheme(themes)(withRouter(function (props: Props) {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    const initialValues = React.useMemo(() => {
        const values = state.lead.fields
        values.anonymousDonation = state.lead.fields.anonymousDonation || false
        values.donationType = state.lead.fields.donationType || 'one-time'
        values.metaInfo = state.lead.fields.metaInfo || {}

        // has no donation selected by the user
        if (!values.hasOwnProperty('donationAmount') || values.donationAmount === null) {
            // if we have defaultDonationAmount set it as default
            if (state.campaign.website.defaultDonationAmount) {
                values.donationAmount = state.campaign.website.defaultDonationAmount
            }

            // if lead has previous donation use as default
            if (state.lead.fields.previousDonationAmount) {
                values.donationAmount = state.lead.fields.previousDonationAmount
            }
        }

        // if only 1 donationDesignation set is as default
        if (state.campaign.website.donationDesignations && state.campaign.website.donationDesignations.length === 1) {
            values.donationDesignations = [{
                type: state.campaign.website.donationDesignations[0],
                value: values.donationAmount
            }]
        }
        // if we have defaultDonationDesignation set is as default
        if (state.campaign.website.defaultDonationDesignation &&
            (typeof values.donationDesignations === 'undefined' || values.donationDesignations === null)
        ) {
            values.donationDesignations = [{
                type: state.campaign.website.defaultDonationDesignation,
                value: values.donationAmount
            }]
        }

        return values
    }, [
        state.campaign.website,
        state.lead.fields
    ])

    return (
        <>
            {submitted ? <Payment /> : null}
            <TopBar />
            <Masthead />
            <PageContainer style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className={styles.formWrapper}>
                    <LeadGenForm
                        initialValues={initialValues}
                        onSubmit={async (data, done) => {
                            await updateLead(dispatch, data, true)
                            setSubmitted(true)
                            done()
                        }}
                    />
                </div >
                <div className={styles.sidebar}>
                    <If condition={state.campaign.website.stats}>
                        <Stats stats={state.campaign.website.stats} />
                    </If>
                    <Testimonials />
                </div>
            </PageContainer>

            <PageFooter />

            <PageFooterContactInfo />
        </>
    )
}))
