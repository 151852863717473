// @flow
import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import CardSection from '../CardSection/index'
import './index.css'
import { preProcessPayment, processPayment } from 'src/Actions'
import cn from 'classnames'

type Props = {
    onSuccess: Function,
    leadFields: Object,
    donationAmount: number,
    stripe: *,
    elements: *
}

export default class Form extends React.Component {
    constructor (props: Props) {
        super(props)

        this.state = {
            error: null,
            loading: false
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault()

        this.setState({ loading: true })

        const { stripe, elements } = this.props

        if (!stripe || !elements) {
            return
        }

        const preProcessPaymentResponse = await preProcessPayment(this.props.donationAmount, {})

        const result = await stripe.confirmCardPayment(preProcessPaymentResponse.token, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: this.props.leadFields.firstName + ' ' + this.props.leadFields.lastName,
                    email: this.props.leadFields.email ? this.props.leadFields.email : null,
                    phone: this.props.leadFields.homePhone ? this.props.leadFields.homePhone : null,
                    address: {
                        city: this.props.leadFields.city,
                        line1: this.props.leadFields.addressLine1,
                        postal_code: this.props.leadFields.postalCode,
                        state: this.props.leadFields.province
                    }
                }
            }
        })

        if (result.error) {
            this.setState({ error: result.error.message })
            console.log(result.error.message)
        } else {
            if (result.paymentIntent.status === 'succeeded') {
                try {
                    await processPayment(this.props.donationAmount, result)
                    this.props.onSuccess(true)
                } catch (err) {
                    alert('Oops something went wrong.')
                    console.error(err)
                }
            }
        }
        this.setState({ loading: false })
    }

    render () {
        return (
            <form className='StripeForm' onSubmit={(event) => {
                if (!this.state.loading) {
                    this.handleSubmit(event)
                }
            }}>
                <div className={'form-row'}>
                    <CardSection onError={(error) => this.setState({ error: error })}/>
                    {this.state.error && <div className="card-errors" role="alert">{this.state.error}</div>}
                </div>
                <button type={'submit'} disabled={this.state.loading}>
                    <div className={cn('spinner', { 'hidden': !this.state.loading })}/>
                    <span id="button-text" className={cn({ 'hidden': this.state.loading })}>Submit my donation</span>
                </button>
            </form>
        )
    }
}
