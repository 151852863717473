// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import * as Form from 'src/components/Form'
import { NMICollectField } from 'src/components/Form/Fields/Field'
import SuccessModal from 'src/components/Payment/SuccessModal'

import withForm from 'src/components/Form/Helpers/FormHOC'

import styles from './styles.module.scss'

import themes, { type Theme } from './themes/__supportedThemes'
import transDomain from './translations/index.translations'
import withTheme from 'src/hoc/withTheme'
import { Store } from 'src/Store'
import { processPayment } from 'src/Actions'

const JS_SCRIPT_ID = 'collect-js-script'

export default function () {
    const { state } = React.useContext(Store)
    const fields = state.lead.fields
    const [status, setStatus] = React.useState('pending')

    React.useEffect(() => {
        const script = document.createElement('script')
        script.id = JS_SCRIPT_ID
        script.src = 'https://secure.networkmerchants.com/token/Collect.js'
        script.setAttribute('data-tokenization-key', state.campaign['payment-gateways']['nmi']['tokenizationKey'])

        document.body.appendChild(script)

        script.onload = () => {
            // eslint-disable-next-line no-undef
            CollectJS.configure({
                paymentSelector: '#payment-submit',
                fields: {
                    ccnumber: {
                        'selector': '#payment-cc-number',
                        'title': 'Card Number',
                        'placeholder': '0000 0000 0000 0000'
                    },
                    ccexp: {
                        'selector': '#payment-exp',
                        'title': 'Card Expiration',
                        'placeholder': '00 / 00'
                    },
                    cvv: {
                        display: 'show',
                        selector: '#payment-cvv',
                        title: 'CVV Code',
                        'placeholder': '***'
                    }
                },
                variant: 'inline',
                callback: async function (response) {
                    try {
                        console.log(response)
                        await processPayment(fields.donationAmount, response)
                        setStatus('success')
                    } catch (err) {
                        alert('Oops something went wrong.')
                        console.error(err)
                    }
                }
            })
        }

        return () => document.body.removeChild(document.getElementById(JS_SCRIPT_ID))
    }, [fields.donationAmount, state.campaign])

    return (
        <>
            <PaymentForm
                // tokenizationKey={state.campaign['payment-gateways']['nmi']['tokenizationKey']}
                initialValues={{
                    fullName: `${fields.firstName} ${fields.lastName}`.trim(),
                    zip: fields.postalCode
                }}
                submitted={status === 'success'}
            />
            {status === 'success'
                ? <SuccessModal />
                : null
            }
        </>
    )
}

type PaymentFormProps = {
    loginId: string,
    transactionKey: string,
    clientKey: string,
    values: {
        name: ?string,
        credit_card: {
            number: ?string,
            expiration_month: ?number,
            expiration_year: ?number,
            cvv: ?number,
            zip: ?string
        }
    },
    handleFieldChange: (key: string, value: ?string) => void,
    theme: Theme,
    submitted: boolen,
    isLoading: boolean,
    tokenizationKey: string
}

const PaymentForm = withTheme(themes)(withForm(true)(function PaymentForm (props: PaymentFormProps) {
    const { t } = useTranslation(transDomain)

    return (
        <div className={styles.wrapper} style={{ '--border-color-wrapper': props.theme.borderColor }}>
            <div className={styles.inner}>
                <Form.Row>
                    <Form.Field
                        defaultValue={props.values.fullName || null}
                        onChange={value => props.handleFieldChange('fullName', value)}
                        type='text'
                        label={t('field.fullName.label')}
                        id='payment-name'
                    />
                </Form.Row>

                <h3 style={{ marginTop: '2.75em' }}>{t('section.creditCardInfo.heading')}</h3>

                <Form.Row>
                    <NMICollectField id='payment-cc-number' />
                </Form.Row>

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn size={1 / 2}>
                            <NMICollectField id='payment-exp' />
                        </Form.RowColumn>

                        <Form.RowColumn size={1 / 2}>
                            <NMICollectField id='payment-cvv' />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                <Form.Row>
                    <Form.Submit
                        id='payment-submit'
                        label={!props.submitted ? t('submit.label') : t('submit.success.label')}
                        disabled={props.submitted}
                        isLoading={props.isLoading}
                        style={{ background: props.theme.borderColor }}
                    />
                </Form.Row>
            </div>
        </div>
    )
}))
