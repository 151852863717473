// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import * as Form from 'src/components/Form'
import SuccessModal from 'src/components/Payment/SuccessModal'
import withForm from 'src/components/Form/Helpers/FormHOC'

import { processPayment, processSubscription } from 'src/Actions'

import styles from './styles.module.scss'

import themes, { type Theme } from './themes/__supportedThemes'

import transDomain from './translations/index.translations'
import withTheme from 'src/hoc/withTheme'
import { Store } from 'src/Store'

type Props = {
    loginId: string,
    transactionKey: string,
    clientKey: string,
    values: {
        name: ?string,
        credit_card: {
            number: ?string,
            expiration_month: ?number,
            expiration_year: ?number,
            cvv: ?number,
            zip: ?string
        }
    },
    handleFieldChange: (key: string, value: ?string) => void,
    theme: Theme,
    submitted: boolen,
    isLoading: boolean
}

export default function () {
    const { state } = React.useContext(Store)
    const fields = state.lead.fields
    const [status, setStatus] = React.useState('pending')

    async function submitHandler (data, done) {
        switch (state.lead.fields.donationType) {
            case 'one-time':
                try {
                    await processPayment(fields.donationAmount, {})
                    setStatus('success')
                } finally {
                    done()
                }

                break
            case 'monthly':
                try {
                    processSubscription(fields.donationAmount, {})
                    setStatus('success')
                } finally {
                    done()
                }
                break
            default:
                alert('Something went wrong')
                console.error(`Unrecognized donationType '${state.lead.fields.donationType}'`)
        }
    }

    return (
        <>
            <PaymentForm
                initialValues={{
                    fullName: `${fields.firstName} ${fields.lastName}`.trim(),
                    zip: fields.postalCode
                }}
                onSubmit={submitHandler}
                submitted={status === 'success'}
            />
            {status === 'success'
                ? <SuccessModal />
                : null
            }
        </>
    )
}

const PaymentForm = withTheme(themes)(withForm(true)(function PaymentForm (props: Props) {
    const { t } = useTranslation(transDomain)

    return (
        <div className={styles.wrapper} style={{ '--border-color-wrapper': props.theme.borderColor }}>
            <div className={styles.inner}>
                <Form.Row>
                    <div className={styles.warning}>
                        This form is for demo purposes only.<br />No charge will be attempted and the form data will be
                        ignored.
                    </div>
                </Form.Row>

                <Form.Row>
                    <Form.Field
                        defaultValue={props.values.fullName || null}
                        onChange={value => props.handleFieldChange('fullName', value)}
                        type='text'
                        label={t('field.fullName.label')}
                    />
                </Form.Row>

                <h3 style={{ marginTop: '2.75em' }}>{t('section.creditCardInfo.heading')}</h3>

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn size={1 / 2}>
                            <Form.Field
                                defaultValue={'4111 1111 1111 1111'}
                                onChange={value => props.handleFieldChange('cardNumber', value)}
                                type='credit-card'
                                label={t('field.cardNumber.label')}
                            />
                        </Form.RowColumn>

                        <Form.RowColumn size={1 / 4}>
                            <Form.Field
                                defaultValue={new Date().getMonth()}
                                onChange={value => props.handleFieldChange('month', value)}
                                type='text'
                                label={t('field.month.label')}
                            />
                        </Form.RowColumn>

                        <Form.RowColumn size={1 / 4}>
                            <Form.Field
                                defaultValue={new Date().getFullYear() + 1}
                                onChange={value => props.handleFieldChange('year', value)}
                                type='text'
                                label={t('field.year.label')}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn size={1 / 2}>
                            <Form.Field
                                defaultValue={'123'}
                                onChange={value => props.handleFieldChange('cardCode', value)}
                                type='number'
                                label={t('field.cardCode.label')}
                            />
                        </Form.RowColumn>

                        <Form.RowColumn size={1 / 2}>
                            <Form.Field
                                defaultValue={props.values.zip || null}
                                onChange={value => props.handleFieldChange('zip', value)}
                                type='text'
                                label={t('field.zip.label')}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                <Form.Row>
                    <Form.Submit
                        label={!props.submitted ? t('submit.label') : t('submit.success.label')}
                        disabled={props.submitted}
                        isLoading={props.isLoading}
                        style={{ background: props.theme.borderColor }}
                    />
                </Form.Row>
            </div>
        </div>
    )
}))
