// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import IsDemoMode from 'src/IsDemoMode'
import * as Form from 'src/components/Form'
import SuccessModal from 'src/components/Payment/SuccessModal'
import withForm from 'src/components/Form/Helpers/FormHOC'

import { processPayment, processSubscription } from 'src/Actions'

import styles from './styles.module.scss'

import themes, { type Theme } from './themes/__supportedThemes'

import transDomain from './translations/index.translations'
import withTheme from 'src/hoc/withTheme'
import { Store } from 'src/Store'

type Props = {
    loginId: string,
    transactionKey: string,
    clientKey: string,
    values: {
        name: ?string,
        credit_card: {
            number: ?string,
            expiration_month: ?number,
            expiration_year: ?number,
            cvv: ?number,
            zip: ?string
        }
    },
    handleFieldChange: (key: string, value: ?string) => void,
    theme: Theme,
    submitted: boolen,
    isLoading: boolean
}

const ACCEPT_JS_SCRIPT_ID = 'accept-js-script'

export default function () {
    const { state } = React.useContext(Store)
    const fields = state.lead.fields
    const [status, setStatus] = React.useState('pending')

    async function responseHandler (data, done) {
        if (typeof data.messages.message[0] !== 'undefined' && data.messages.message[0].code === 'E_WC_14') {
            /**
             * Accept.js makes an OPTIONS request due to CORS and it returns a bullshit response.
             * apparently the only solution is to ignore the response :|
             * https://community.developer.authorize.net/t5/Integration-and-Testing/Accept-js-E00003-Root-element-is-missing/m-p/63912#M37926
             */
            return
        }

        if (data.messages.resultCode !== 'Ok') {
            alert(data.messages.message[0].text)
            console.error(data)
            done()
            return
        }

        switch (state.lead.fields.donationType) {
            case 'one-time':
                try {
                    await processPayment(fields.donationAmount, data.opaqueData)
                    setStatus('success')
                } finally {
                    done()
                }

                break
            case 'monthly':
                try {
                    processSubscription(fields.donationAmount, data.opaqueData)
                    setStatus('success')
                } finally {
                    done()
                }
                break
            default:
                alert('Something went wrong')
                console.error(`Unrecognized donationType '${state.lead.fields.donationType}'`)
        }
    }

    function submitHandler (data, done) {
        data.cardNumber = data.cardNumber.replace(/[^\d]/g, '')

        const dispatchData = {
            authData: {
                clientKey: state.campaign['payment-gateways']['authorize.net'].clientKey,
                apiLoginID: state.campaign['payment-gateways']['authorize.net'].apiLoginID
            },
            cardData: data
        }

        window.Accept.dispatchData(dispatchData, async data => {
            await responseHandler(data, done)
        })
    }

    return (
        <>
            <PaymentForm
                initialValues={{
                    fullName: `${fields.firstName} ${fields.lastName}`.trim(),
                    zip: fields.postalCode
                }}
                onSubmit={submitHandler}
                submitted={status === 'success'}
            />
            {status === 'success'
                ? <SuccessModal />
                : null
            }
        </>
    )
}

const PaymentForm = withTheme(themes)(withForm(true)(function PaymentForm (props: Props) {
    const { t } = useTranslation(transDomain)

    React.useEffect(() => {
        const script = document.createElement('script')
        script.id = ACCEPT_JS_SCRIPT_ID

        if (IsDemoMode) {
            script.src = 'https://jstest.authorize.net/v1/Accept.js'
        } else {
            script.src = 'https://js.authorize.net/v1/Accept.js'
        }

        document.body.appendChild(script)

        return () => document.body.removeChild(document.getElementById(ACCEPT_JS_SCRIPT_ID))
    }, [])

    return (
        <div className={styles.wrapper} style={{ '--border-color-wrapper': props.theme.borderColor }}>
            <div className={styles.inner}>
                <Form.Row>
                    <Form.Field
                        defaultValue={props.values.fullName || null}
                        onChange={value => props.handleFieldChange('fullName', value)}
                        type='text'
                        label={t('field.fullName.label')}
                    />
                </Form.Row>

                <h3 style={{ marginTop: '2.75em' }}>{t('section.creditCardInfo.heading')}</h3>

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn size={1 / 2}>
                            <Form.Field
                                defaultValue={props.values.cardNumber || null}
                                onChange={value => props.handleFieldChange('cardNumber', value)}
                                type='credit-card'
                                label={t('field.cardNumber.label')}
                            />
                        </Form.RowColumn>

                        <Form.RowColumn size={1 / 4}>
                            <Form.Field
                                defaultValue={''}
                                onChange={value => props.handleFieldChange('month', value)}
                                type='credit-card-month'
                                label={t('field.month.label')}
                            />
                        </Form.RowColumn>

                        <Form.RowColumn size={1 / 4}>
                            <Form.Field
                                defaultValue={''}
                                onChange={value => props.handleFieldChange('year', value)}
                                type='credit-card-year'
                                label={t('field.year.label')}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn size={1 / 2}>
                            <Form.Field
                                defaultValue={''}
                                onChange={value => props.handleFieldChange('cardCode', value)}
                                type='text'
                                label={t('field.cardCode.label')}
                            />
                        </Form.RowColumn>

                        <Form.RowColumn size={1 / 2}>
                            <Form.Field
                                defaultValue={props.values.zip || null}
                                onChange={value => props.handleFieldChange('zip', value)}
                                type='text'
                                label={t('field.zip.label')}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                <Form.Row>
                    <Form.Submit
                        label={!props.submitted ? t('submit.label') : t('submit.success.label')}
                        disabled={props.submitted}
                        isLoading={props.isLoading}
                        style={{ background: props.theme.borderColor }}
                    />
                </Form.Row>
            </div>
        </div>
    )
}))
