// @flow
import React from 'react'

import styles from './styles.module.scss'

import { withTranslation } from 'react-i18next'
import transDomain from './translations/index.translations'

import withTheme from 'src/hoc/withTheme'
import themes, { type Theme } from './themes/__supportedThemes'
import Content from 'src/components/Content'

type Props = {
    theme: Theme
}

export default withTranslation(transDomain)(withTheme(themes)(function PageFooter (props: Props) {
    return (
        <div
            className={styles.footer}
            style={{
                '--background-image': `url(${props.theme.bgImage})`,
                '--font-family-message': props.theme.fontFamilyMessage
            }}
        >
            {props.theme.logoPosition === 'top' && <img src={props.theme.logo} alt='Logo' className={styles.logo} />}

            <Content wrapper={'h1'} contentKey={'pageFooter.message'}>
                With Your Help, We Can Support Our Students' Dreams.
            </Content>

            {props.theme.logoPosition === 'bottom' && <img src={props.theme.logo} alt='Logo' className={styles.logo} />}
        </div>
    )
}))
