// @flow
import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import './index.css'

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    }
}

export default function Index (props) {
    const handleChange = (event) => {
        if (event.error) {
            props.onError(event.error.message)
        } else {
            props.onError(null)
        }
    }

    return (
        <CardElement options={CARD_ELEMENT_OPTIONS} onChange={handleChange}/>
    )
}
