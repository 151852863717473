// @flow
import React from 'react'

import { Store } from 'src/Store'
import { sendAutoresponder } from 'src/Actions'

import { Currency } from 'src/i18n/NumberFormatter'
import withTheme from 'src/hoc/withTheme'

import Modal from 'src/components/Modal'
import Typography from 'src/components/Typography'

import themes from './themes/__supportedThemes'
import styles from './styles.module.scss'

export default withTheme(themes)(function SuccessModal () {
    const { state, dispatch } = React.useContext(Store)
    const fields = state.lead.fields
    const currency = React.useMemo(() => Currency(state.currency, state.locale), [state.currency, state.locale])

    React.useEffect(() => {
        if (state.campaign.hasOwnProperty('autoresponder')) {
            sendAutoresponder(dispatch)
        }
    }, [dispatch, state.campaign])

    return (
        <Modal isOpen closeFromOverlay={false} closeByEscape={false} showCloseButton={false}>
            <div className={styles.content}>
                <Typography isCentered>
                    <h1>Thank You {fields.firstName},<br />With Your {currency.format(fields.donationAmount)}
                        {fields.donationType !== 'one-time' ? ' ' + fields.donationType : ''} Donation, We Can Help Make
                        Students’ Dreams Come True.</h1>

                    {typeof state.campaign.website.payment !== 'undefined' &&
                    typeof state.campaign.website.payment.successModal !== 'undefined' &&
                    typeof state.campaign.website.payment.successModal.url !== 'undefined'
                        ? (
                            <p>
                                <a href={state.campaign.website.payment.successModal.url}>
                                    {state.campaign.website.payment.successModal.url}
                                </a>
                            </p>
                        )
                        : null
                    }
                </Typography>
            </div>
        </Modal>
    )
})
