import 'react-app-polyfill/ie11'
import 'element-remove'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import './index.css'
import App from './App'
import { StoreProvider } from './Store'
import './i18n.js'
import { runBrowserUpdate } from '@shift-marketing/event-websites-helper'

runBrowserUpdate()

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })
}

if (window.location.search === '?trigger=error') {
    throw new Error('triggered')
}

// window.addEventListener('resize', function () {
//     window.top.postMessage(document.body.scrollHeight, '*')
// })

// TODO: Separate translations into each component => https://stackoverflow.com/a/53433779/3173518

ReactDOM.render(
    <StoreProvider>
        <App />
    </StoreProvider>,
    document.getElementById('root')
)
