// @flow
import React from 'react'

import styles from './PageContainer.module.scss'

export default function PageContainer ({ children, style, width }) {
    if (width) {
        style = { ...style, maxWidth: width }
    }

    return (
        <div className={styles.container} style={style}>{children}</div>
    )
}

PageContainer.defaultProps = {
    style: {}
}
