// @flow

import React from 'react'
import cn from 'classnames'

import { withTranslation } from 'react-i18next'
import transDomain from './translations/index.translations'

import withTheme from 'src/hoc/withTheme'
import themes, { Theme } from './themes/__supportedThemes'

import styles from './styles.module.scss'
import Content from 'src/components/Content'

type Props = {
    theme: Theme,
    stats: Array<{value: string, label: string}>
}

export default withTranslation(transDomain)(withTheme(themes)(function Stats (props: Props) {
    return (
        <>
            <Content
                wrapper={'h2'}
                className={cn(styles.heading, {
                    [styles.hasOnlyLabels]: props.theme.hasOnlyLabels
                })}
                contentKey={'sidebar.stats.heading'}
                style={{
                    color: props.theme.headingColor,
                    '--font-family-value': props.theme.fontValue
                }}
            />

            <div
                className={cn(styles.stats, {
                    [styles.hasGradientBackground]: props.theme.hasGradientBackground
                })}
                style={{
                    '--background-color': props.theme.bgColor,
                    '--color-value-highlight': props.theme.colorValueHighlight,
                    '--size-value-highlight': props.theme.sizeValueHighlight,
                    '--background-image': `url(${props.theme.bgImage})`,
                    '--background-image-height': `${props.theme.bgImageHeight}px`
                }}
            >
                {props.stats.map((stat, index) => (
                    <div key={index} className={cn(styles.item, {
                        [styles.hasOnlyLabels]: props.theme.hasOnlyLabels
                    })}>
                        <StatsValue isHighlighted={stat.highlight}>{stat.value}</StatsValue>
                        <StatsLabel>{stat.label}</StatsLabel>
                    </div>
                ))}
            </div>

            {props.theme.showDescription &&
                <div className={styles.description} style={{ background: props.theme.descriptionBg }}>
                    <div className={styles.descriptionContent}>
                        <Content contentKey={'sidebar.stats.description'}>
                            <p>Scholarships are awarded to both part-time and full-time students in all
                            undergraduate and graduate programs.</p>
                        </Content>
                    </div>
                </div>
            }
        </>
    )
}))

const StatsLabel = ({ children }) => <div className={styles.label} dangerouslySetInnerHTML={{ __html: children }} />
const StatsValue = withTheme(themes)(({ children, isHighlighted, theme }) => (
    <div
        className={cn(styles.value, { [styles.isHighlighted]: isHighlighted, [styles.strong]: theme.strongValue })}
        style={{ '--color-value': theme.colorValue, '--color-value-highlight': theme.colorValueHighlight, '--font-family-value': theme.fontValue }}
    >
        {children}
    </div>
))
