// @flow
import React from 'react'
import { Store } from 'src/Store'
import { processPayment } from 'src/Actions'
import { Redirect } from 'react-router'

export default function () {
    const { state: { campaign, lead } } = React.useContext(Store)
    const [success, setSuccess] = React.useState(false)

    React.useEffect(() => {
        const paymentGateway = Object.keys(campaign['payment-gateways'])[0]

        if (paymentGateway !== 'blackbaud') return

        const script = document.createElement('script')
        script.setAttribute('src', 'https://payments.blackbaud.com/Checkout/bbCheckout.2.0.js')

        script.onload = function () {
            window.bbCheckout2_0.init()

            window.Blackbaud_OpenPaymentForm({
                key: campaign['payment-gateways'].blackbaud.publicKey,
                merchant_account_id: campaign['payment-gateways'].blackbaud.merchantId,
                client_app_name: 'Alumni Gift',
                amount: lead.fields.donationAmount,
                billing_address_email: lead.fields.email,
                billing_address_line: lead.fields.addressLine1,
                billing_address_city: lead.fields.city,
                billing_address_state: lead.fields.province,
                billing_address_post_code: lead.fields.postalCode
            })

            document.addEventListener('checkoutComplete', function (event) {
                processPayment(lead.fields.donationAmount, { transactionToken: event.detail.transactionToken }).then()
                setSuccess(true)
            })
        }

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [campaign, lead])

    if (success) {
        const path = window.location.pathname.substr(1).split('/')[0]
        return <Redirect to={`${path}/success${window.location.search}`} />
    }

    return <></>
}
