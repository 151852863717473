// @flow
import * as React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import styles from './styles.module.scss'
import withTheme from 'src/hoc/withTheme'
import themes, { Theme } from './themes/__supportedThemes'

export type Props = {
    id?: string,
    children: React.Node,
    href: ?string,
    onClick: ?Function,
    to?: string,
    color?: string,
    icon?: string,
    size?: 'small' | 'medium' | 'regular' | 'large',
    padding: null | 'regular',
    outlined: boolean,
    inverted: boolean,
    rounded: boolean,
    link: boolean,
    hover: boolean,
    focus: boolean,
    active: boolean,
    loading: boolean,
    disabled: boolean,
    style?: string,
    iconPosition: 'start' | 'end',
    iconSize?: number,
    isSubmit: Boolean,
    theme: Theme
}

type LinkProps = Props & {
    href: string
}

type ButtonProps = Props & {
    onClick: Function
}

function buildButtonClasses (props: Props): Object {
    let classes = {
        [styles.button]: true,
        [styles.icon]: props.icon,
        [styles.isOutlined]: props.outlined,
        [styles.isInverted]: props.inverted,
        [styles.isRounded]: props.rounded,
        [styles.isLink]: props.link,
        [styles.isHover]: props.hover,
        [styles.isFocus]: props.focus,
        [styles.isActive]: props.active,
        [styles.isLoading]: props.loading,
        [styles.isDisabled]: props.disabled
    }

    if (props.color) {
        classes[`__color-${props.color}`] = true
    }

    if (props.size) {
        classes[`__size-${props.size}`] = true
    }

    if (props.padding !== 'regular') {
        classes[`__padding-${props.padding || 'none'}`] = true
    }

    return classes
}

export const Button = withTheme(themes)(function Button (props: LinkProps | ButtonProps) {
    let content = props.children

    if (props.icon) {
        const iconImg = <div className={styles.icon}>
            <img src={props.icon} alt='Icon' style={{ height: props.iconSize ? props.iconSize + 'px' : null }} />
        </div>

        content = (
            <>
                {props.iconPosition === 'start' ? iconImg : null}
                <div className='sh-button__text'>
                    {props.children}
                </div>
                {props.iconPosition === 'end' ? iconImg : null}
            </>
        )
    }

    const classes = buildButtonClasses(props)

    if (props.to) {
        return <Link className={cn(classes)} to={props.to}>{content}</Link>
    }

    const style = {
        ...(props.style || {}),
        '--color-primary': props.theme.colorPrimary
    }

    return props.href
        ? <a href={props.href} className={cn(classes)}
            style={style} id={props.id || null} target={props.target || null}
        >{content}</a>
        : <button
            id={props.id || null}
            type={props.isSubmit ? 'submit' : 'button'}
            disabled={props.disabled}
            onClick={props.onClick}
            className={cn(classes)}
            style={style}
        >
            {content}
        </button>
})

Button.defaultProps = {
    outlined: false,
    inverted: false,
    rounded: false,
    link: false,
    padding: 'regular',
    hover: false,
    focus: false,
    active: false,
    loading: false,
    disabled: false,
    iconPosition: 'start',
    isSubmit: false
}

export default Button

export const ButtonGroup = ({ children }: { children: React.Node }) => (
    <div className={styles.group}>{children}</div>
)
