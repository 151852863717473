// @flow
import * as React from 'react'

import styles from './styles.module.scss'
import Button from '../Button'

import withTheme from 'src/hoc/withTheme'
import themes, { Theme } from './themes/__supportedThemes'

type FormProps = {
    onClick?: (e: SyntheticEvent) => void,
    onSubmit?: (e: SyntheticEvent) => void,
    children?: ?React.Node,
    theme: Theme
};

export default withTheme(themes)(function Form (props: FormProps) {
    return (
        <form
            onClick={props.onClick}
            onSubmit={props.onSubmit}
            className={styles.form}
            style={{
                '--color-selected': props.theme.colorSelected
            }}
        >
            {props.children}
        </form>
    )
})

export function Row ({ children }: { children: React.Children }) {
    return <div className={styles.row}>{children}</div>
}

export function RowColumns ({ children }: { children: React.Children }) {
    return <div className={styles.rowColumns}>{children}</div>
}

export function RowColumn (props: { children: React.Children, size?: number }) {
    return <div className={styles.rowColumn} style={{ flexBasis: props.size * 100 + '%' }}>{props.children}</div>
}

type SubmitProps = {
    disabled?: boolean,
    isLoading?: boolean,
    label?: string,
    color?: string,
    size?: string
};

export function Divider () {
    return <div className={styles.divider} />
}

export const Submit = withTheme(themes)(function (props: SubmitProps) {
    return (
        <Button id={props.id || null} isSubmit disabled={props.disabled || props.isLoading} loading={props.isLoading} color='primary' onClick={() => {
        }} style={props.style || { backgroundColor: props.theme.submitColorSelected, margin: '0 auto' }}>{props.label}</Button>
    )
})

Submit.defaultProps = {
    isLoading: false,
    color: 'primary',
    size: 'medium',
    cancel: 'Cancel',
    submit: 'Submit'
}

export { default as Field, CurrencyField } from './Fields/Field'
export { default as Label } from './Label'
export { default as Panel } from './Panel'
export { default as Tabs } from './Tabs'
export { default as RadioButtons } from './RadioButtons'
export { default as Choices } from './Fields/Choices'
export { default as Dropdown } from './Fields/Dropdown'
export { default as Checkbox } from './Fields/Checkbox'
