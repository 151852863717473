// @flow
import * as React from 'react'
import SuccessModal from 'src/components/Payment/SuccessModal'
import styles from './styles.module.scss'
import themes from './themes/__supportedThemes'
import withTheme from 'src/hoc/withTheme'
import { Store } from 'src/Store'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './components/Form/index'

export default function () {
    const { state: { campaign, lead } } = React.useContext(Store)

    const [stripe, setStripe] = React.useState(null)
    const [status, setStatus] = React.useState(false)

    React.useEffect(() => {
        const paymentGateway = Object.keys(campaign['payment-gateways'])[0]

        if (paymentGateway !== 'stripe') return

        setStripe(loadStripe(campaign['payment-gateways'][paymentGateway].publishableKey))
    }, [campaign])

    return (
        <>
            <PaymentForm
                stripePromise={stripe}
                donationAmount={lead.fields.donationAmount}
                onSuccess={setStatus}
                leadFields={lead.fields}/>
            {status && <SuccessModal/>}
        </>
    )
}

const PaymentForm = withTheme(themes)(function (props) {
    return (
        <div className={styles.wrapper} style={{ '--border-color-wrapper': props.theme.borderColor }}>
            <div className={styles.inner}>
                <Elements stripe={props.stripePromise}>
                    <ElementsConsumer>
                        {({ stripe, elements }) => (
                            <CheckoutForm
                                stripe={stripe}
                                elements={elements}
                                donationAmount={props.donationAmount}
                                onSuccess={props.onSuccess}
                                leadFields={props.leadFields}
                            />
                        )}
                    </ElementsConsumer>
                </Elements>
            </div>
        </div>
    )
})
