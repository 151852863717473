
        import i18n from 'src/i18n';
        import flattenObject from 'src/util/flattenObject';
    
            import ns3da77327772faed70eed34ca8cfb644een_CA from './en-CA.yaml'
            i18n.addResourceBundle('en-CA', '3da77327772faed70eed34ca8cfb644e', flattenObject(ns3da77327772faed70eed34ca8cfb644een_CA), true, true);
        
            import ns3da77327772faed70eed34ca8cfb644een_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '3da77327772faed70eed34ca8cfb644e', flattenObject(ns3da77327772faed70eed34ca8cfb644een_US), true, true);
        
            import ns3da77327772faed70eed34ca8cfb644een from './en.yaml'
            i18n.addResourceBundle('en', '3da77327772faed70eed34ca8cfb644e', flattenObject(ns3da77327772faed70eed34ca8cfb644een), true, true);
        
        export default '3da77327772faed70eed34ca8cfb644e';
    