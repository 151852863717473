// @flow
import React from 'react'

import styles from './styles.module.scss'

import { Store } from 'src/Store'
import withTheme from 'src/hoc/withTheme'
import themes from './themes/__supportedThemes'

import facebookIcon from './images/facebook.svg'
import flickrIcon from './images/flickr.svg'
import instagramIcon from './images/instagram.svg'
import tumblrIcon from './images/tumblr.svg'
import twitterIcon from './images/twitter.svg'
import youtubeIcon from './images/youtube.svg'

const ICON_MAP = {
    'facebook.com': facebookIcon,
    'youtube.com': youtubeIcon,
    'twitter.com': twitterIcon,
    'instagram.com': instagramIcon,
    'tumblr.com': tumblrIcon,
    'flickr.com': flickrIcon
}

export type Props = {
    twitter?: string,
    facebook?: string,
    instagram?: string,
    tumblr?: string,
    youtube?: string,
    flickr?: string,
}

export default withTheme(themes)(function SocialIcon (props: Props) {
    const { state: { campaign: { socialLinks } } } = React.useContext(Store)

    if (!socialLinks) {
        return <div />
    }

    return (
        <div className={styles.icons} style={{ flex: '0 0 auto', margin: '1em' }}>
            {socialLinks.map((link, index) => {
                const match = link.match(/https?:\/\/([^/]+)\//)
                if (!match) {
                    return null
                }

                const domain = match[1]

                if (typeof ICON_MAP[domain] === 'undefined') {
                    return null
                }

                return (
                    <a href={link} key={index} target={'_blank'}>
                        <img src={ICON_MAP[domain]} alt='Facebook' />
                    </a>
                )
            })}
        </div>
    )
})
